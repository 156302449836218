body{
    padding:0;
    margin:0;
    box-sizing:border-box;
    overflow:hidden;
    width:100vw;
    height:100vh;
}

canvas{
    box-sizing: border-box;
}